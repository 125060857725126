import React, { useRef, useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { TweenLite, CSSPlugin, gsap } from 'gsap/all';

import Layout from "../components/layout"
import "../css/index.css"
import SEO from "../components/seo"
import { social } from '../images/images'

const plugins = [ CSSPlugin ];
gsap.registerPlugin(CSSPlugin)


const IndexPage = () => {
  const circleref = useRef(null);

  useEffect(()=>{
    document.addEventListener('mousemove', moveCircle);
  },[])

  const moveCircle = e => {
    TweenLite.to(circleref.current, 0.3, {
    css: {
      left: e.pageX,
      top: e.pageY
    }
  });
}

  return (
    <>
      <Layout>
        <SEO title="SM" />
        <div ref={circleref} className="circle"></div>
        <a href="https://github.com/memon07" target="_blank" rel="noreferrer">
          <img className="social github" src={social.gitRed} alt="git"/>
        </a>
        <a href="https://www.linkedin.com/in/memon07" target="_blank" rel="noreferrer">
          <img className="social linkedin" src={social.linkedinRed} alt="linkedin"/>
        </a>
        <a href="https://dribbble.com/memon07" target="_blank" rel="noreferrer">
          <img className="social dribbble" src={social.dribbbleRed} alt="dribbble"/>
        </a>
        <a href="https://www.instagram.com/memon07" target="_blank" rel="noreferrer">
          <img className="social instagram" src={social.instagramRed} alt="instagram"/>
        </a>
        <a href="https://memon07art.tumblr.com/" target="_blank" rel="noreferrer">
          <img className="social tumblr" src={social.tumblrRed} alt="tumblr"/>
        </a>
        <h1 className="side__title">
          UI/UX + FRONTEND DEVELOPER 
        </h1>
        <p className="mb-0 inquery__text">For collaboration or inquery please hit me up on <a className="desc-link" style={{borderBottom:'5px solid #363dc3'}} href="mailto:memonshoyeb26@gmail.com" target="_top">mail</a></p>
        <div className="content--fixed index">
          <h1>SHOYEB MEMON.</h1>
          <p className="description">Have worked in startups which ranged from ed-tech ,<br/> healthcare to entertainment sectors.<br/>
          An avid football fan, cheers regularly for chelsea fc.<br/>
          Pixel Artist and a newbie indie game developer.<br/>
          loves to work on making websites look beautiful.<br/>
          currently spreading love in service sector ❤️ ❤️ <br/>
          Traveller<br/>
          </p>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
