import dribbble from './dribbble.svg';
import fb from './facebook.svg';
import git from './github-logo.svg';
import instagram from './instagram.svg';
import twitter from './twitter.svg';
import linkedin from './linkedin.svg';
import gitRed from './github-logo-red.svg';
import linkedinRed from './linkedin-red.svg';
import dribbbleRed from './dribbble-red.svg';
import instagramRed from './instagram-red.svg';
import tumblrRed from './tumblr-red.svg';

export const social = {
    dribbble,
    fb,
    git,
    instagram,
    twitter,
    linkedin,
    gitRed,
    linkedinRed,
    dribbbleRed,
    instagramRed,
    tumblrRed
}